// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-community-template-js": () => import("./../src/templates/communityTemplate.js" /* webpackChunkName: "component---src-templates-community-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cn-community-js": () => import("./../src/pages/cn/community.js" /* webpackChunkName: "component---src-pages-cn-community-js" */),
  "component---src-pages-cn-index-js": () => import("./../src/pages/cn/index.js" /* webpackChunkName: "component---src-pages-cn-index-js" */),
  "component---src-pages-cnt-community-js": () => import("./../src/pages/cnt/community.js" /* webpackChunkName: "component---src-pages-cnt-community-js" */),
  "component---src-pages-cnt-index-js": () => import("./../src/pages/cnt/index.js" /* webpackChunkName: "component---src-pages-cnt-index-js" */),
  "component---src-pages-community-js": () => import("./../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-hockey-community-js": () => import("./../src/pages/hockey_community.js" /* webpackChunkName: "component---src-pages-hockey-community-js" */),
  "component---src-pages-hockey-js": () => import("./../src/pages/hockey.js" /* webpackChunkName: "component---src-pages-hockey-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jp-community-js": () => import("./../src/pages/jp/community.js" /* webpackChunkName: "component---src-pages-jp-community-js" */),
  "component---src-pages-jp-index-js": () => import("./../src/pages/jp/index.js" /* webpackChunkName: "component---src-pages-jp-index-js" */),
  "component---src-pages-kr-community-js": () => import("./../src/pages/kr/community.js" /* webpackChunkName: "component---src-pages-kr-community-js" */),
  "component---src-pages-kr-index-js": () => import("./../src/pages/kr/index.js" /* webpackChunkName: "component---src-pages-kr-index-js" */)
}

